import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";

import SidebarLayout from "src/layouts/SidebarLayout";
import BaseLayout from "src/layouts/BaseLayout";

import SuspenseLoader from "src/components/SuspenseLoader";
import LoggedArea from "./utils/LoggedArea";
import AdminArea from "./utils/AdminArea";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

//Auth
const Login = Loader(lazy(() => import("src/pages/auth/Login")));
const Logout = Loader(lazy(() => import("src/pages/auth/Logout")));
// Pages
const LandingPage = Loader(lazy(() => import("src/pages/landingpage")));
const FAQPage = Loader(lazy(() => import("src/pages/landingpage/FAQ")));
const Daftar = Loader(lazy(() => import("src/pages/landingpage/Daftar")));
const Aktivasi = Loader(lazy(() => import("src/pages/landingpage/Aktivasi")));

// Status

const Status404 = Loader(
  lazy(() => import("src/pages/landingpage/Status/Status404"))
);
const Status500 = Loader(
  lazy(() => import("src/pages/landingpage/Status/Status500"))
);
const StatusComingSoon = Loader(
  lazy(() => import("src/pages/landingpage/Status/ComingSoon"))
);
const StatusMaintenance = Loader(
  lazy(() => import("src/pages/landingpage/Status/Maintenance"))
);

const Overview = Loader(lazy(() => import("src/pages/overview")));
const PojokEdukasi = Loader(lazy(() => import("src/pages/Pojok_edukasi")));
const PiringBerbagi = Loader(lazy(() => import("src/pages/Piring_berbagi")));
const BankKompos = Loader(lazy(() => import("src/pages/Bank_kompos")));
const BerdikariMart = Loader(lazy(() => import("src/pages/Berdikari_mart")));

// Dashboards
//const Aktivitas = Loader(lazy(() => import("src/pages/dashboards/Aktivitas")));
//const Notifikasi = Loader(
//  lazy(() => import("src/pages/dashboards/Notifikasi"))
//);

// Systems
//const UserRole = Loader(lazy(() => import("src/pages/systems/User/Role")));
//const UserList = Loader(lazy(() => import("src/pages/systems/User/List")));
const User = Loader(lazy(() => import("src/pages/master/User")));
const Radius = Loader(lazy(() => import("src/pages/master/Pengaturan_radius")));
const Poin = Loader(lazy(() => import("src/pages/master/Konversi_poin")));
const Article = Loader(
  lazy(() => import("src/pages/master/Article_categories"))
);
const Satuan = Loader(lazy(() => import("src/pages/master/Satuan")));
const Faq = Loader(lazy(() => import("src/pages/master/Faq")));
const Contact = Loader(lazy(() => import("src/pages/master/Contact")));
//const FoodAgreement = Loader(lazy(() => import("src/pages/master/Agremeent")));
const AsalMakanan = Loader(lazy(() => import("src/pages/master/Asal_makanan")));
const TipeMakanan = Loader(lazy(() => import("src/pages/master/Food_type")));
const MetodePengiriman = Loader(
  lazy(() => import("src/pages/master/Deliver_type"))
);

//const UserForm = Loader(lazy(() => import("src/pages/systems/User/UserForm")));

const Settings = Loader(lazy(() => import("src/pages/systems/Settings")));

// Akun
const UserProfile = Loader(lazy(() => import("src/pages/akun/profile")));
const UserSettings = Loader(lazy(() => import("src/pages/akun/settings")));

// Managements
//const Unit = Loader(lazy(() => import("src/pages/managements/Unit")));

//const Elemen = Loader(lazy(() => import("src/pages/managements/Elemen")));

//const Produsen = Loader(lazy(() => import("src/pages/managements/Produsen")));
//const IGT = Loader(lazy(() => import("src/pages/managements/IGT")));
//const Pemeriksaan = Loader(
//  lazy(() => import("src/pages/managements/Pemeriksaan"))
//);
//const Wilayah = Loader(lazy(() => import("src/pages/managements/Wilayah")));
//const Eksternal = Loader(lazy(() => import("src/pages/managements/Eksternal")));
//const Internal = Loader(lazy(() => import("src/pages/managements/Internal")));

// data
const routes = [
  {
    path: "",
    element: <BaseLayout />,
    children: [
      {
        path: "/",
        element: <LandingPage />,
      },
      {
        path: "auth",
        children: [
          {
            path: "",
            element: <Navigate to="login" replace />,
          },
          {
            path: "login",
            element: <Login />,
          },
          {
            path: "logout",
            element: <Logout />,
          },
        ],
      },
      {
        path: "daftar",
        element: <Daftar />,
      },
      {
        path: "aktivasi",
        element: <Aktivasi />,
      },
      {
        path: "faq",
        element: <FAQPage />,
      },
      {
        path: "status",
        children: [
          {
            path: "",
            element: <Navigate to="404" replace />,
          },
          {
            path: "404",
            element: <Status404 />,
          },
          {
            path: "500",
            element: <Status500 />,
          },
          {
            path: "maintenance",
            element: <StatusMaintenance />,
          },
          {
            path: "coming-soon",
            element: <StatusComingSoon />,
          },
        ],
      },
      {
        path: "*",
        element: <Status404 />,
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <LoggedArea>
        <SidebarLayout />
      </LoggedArea>
    ),
    children: [
      {
        path: "",
        element: <Overview />,
      },
    ],
  },
  {
    path: "pojok-edukasi",
    element: (
      <LoggedArea>
        <SidebarLayout />
      </LoggedArea>
    ),
    children: [
      {
        path: "",
        element: <PojokEdukasi />,
      },
    ],
  },
  {
    path: "piring-berbagi",
    element: (
      <LoggedArea>
        <SidebarLayout />
      </LoggedArea>
    ),
    children: [
      {
        path: "",
        element: <PiringBerbagi />,
      },
    ],
  },
  {
    path: "bank-kompos",
    element: (
      <LoggedArea>
        <SidebarLayout />
      </LoggedArea>
    ),
    children: [
      {
        path: "",
        element: <BankKompos />,
      },
    ],
  },
  {
    path: "berdikari-mart",
    element: (
      <LoggedArea>
        <SidebarLayout />
      </LoggedArea>
    ),
    children: [
      {
        path: "",
        element: <BerdikariMart />,
      },
    ],
  },
  {
    path: "master",
    element: (
      <LoggedArea>
        <SidebarLayout />
      </LoggedArea>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="article" replace />,
      },
      {
        path: "article",
        element: (
          <AdminArea>
            <Article />
          </AdminArea>
        ),
      },

      {
        path: "satuan",
        element: (
          <AdminArea>
            <Satuan />
          </AdminArea>
        ),
      },
      {
        path: "role",
        element: (
          <AdminArea>
            <User />
          </AdminArea>
        ),
      },
      {
        path: "pengaturan-radius",
        element: (
          <AdminArea>
            <Radius />
          </AdminArea>
        ),
      },
      {
        path: "konversi-poin",
        element: (
          <AdminArea>
            <Poin />
          </AdminArea>
        ),
      },
      {
        path: "faq",
        element: (
          <AdminArea>
            <Faq />
          </AdminArea>
        ),
      },
      {
        path: "contact",
        element: (
          <AdminArea>
            <Contact />
          </AdminArea>
        ),
      },
      {
        path: "tipe-makanan",
        element: (
          <AdminArea>
            <TipeMakanan />
          </AdminArea>
        ),
      },
      {
        path: "metode-pengiriman",
        element: (
          <AdminArea>
            <MetodePengiriman />
          </AdminArea>
        ),
      },
      {
        path: "asal-makanan",
        element: (
          <AdminArea>
            <AsalMakanan />
          </AdminArea>
        ),
      },

      {
        path: "konversi-poin",
        element: (
          <AdminArea>
            <Poin />
          </AdminArea>
        ),
      },
      /*
      {
        path: "user",
        children: [
          {
            path: "",
            element: <Navigate to="role" replace />,
          },
          {
            path: "role",
            element: <User tab="role" />,
          },
          {
            path: "list",
            element: <User tab="list" />,
          },
          {
            path: "add",
            element: <UserForm title="Add User" caption="Penambahan User" />,
          },
        ],
      },
      */
    ],
  },
  {
    path: "akun",
    element: (
      <LoggedArea>
        <SidebarLayout />
      </LoggedArea>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="profile" replace />,
      },
      {
        path: "profile",
        children: [
          {
            path: "",
            element: <Navigate to="details" replace />,
          },
          {
            path: "details",
            element: <UserProfile />,
          },
          {
            path: "settings",
            element: <UserSettings />,
          },
        ],
      },
    ],
  },
  /*
  {
    path: "dashboards",
    element: (
      <LoggedArea>
        <SidebarLayout />
      </LoggedArea>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="aktivitas" replace />,
      },
      {
        path: "aktivitas",
        element: <Aktivitas />,
      },
      {
        path: "notifikasi",
        element: <Notifikasi />,
      },
    ],
  },
  
  {
    path: "managements",
    element: (
      <LoggedArea>
        <SidebarLayout />
      </LoggedArea>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="akuntansi" replace />,
      },
      {
        path: "akuntansi",
        element: <Akuntansi />,
      },
      {
        path: "anggota",
        element: <Anggota />,
      },
      {
        path: "simpanan",
        element: <Simpanan />,
      },
      {
        path: "pinjaman",
        element: <Pinjaman />,
      },
      {
        path: "saldo-akun",
        element: <Saldo_akun />,
      },
    ],
  },
  {
    path: "laporan",
    element: (
      <LoggedArea>
        <SidebarLayout />
      </LoggedArea>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="neraca" replace />,
      },
      {
        path: "neraca",
        element: <Neraca />,
      },
      {
        path: "laba-rugi",
        element: <LabaRugi />,
      },
      {
        path: "arus-kas",
        element: <ArusKas />,
      },
    ],
  },
  {
    path: "transaksi",
    element: (
      <LoggedArea>
        <SidebarLayout />
      </LoggedArea>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="simpanan" replace />,
      },
      {
        path: "simpanan",
        element: <TSimpanan />,
      },
      {
        path: "pinjaman",
        element: <TPinjaman />,
      },
      {
        path: "kas",
        element: <TKas />,
      },
    ],
  },
  */
];

export default routes;
